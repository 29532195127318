<template>
  <div class="activity-apply wrapper page-wrap">
    <a-spin :spinning="loading">
      <activity-top :data="activity"></activity-top>
      <div class="line"></div>
      <a-form :label-col="labelCol" class="apply-form" v-if="activity_apply">
        <a-form-item name="apply_name" label="姓名">
          <a-input
            size="large"
            v-model:value="activity_apply.apply_name"
            readonly
          >
          </a-input>
        </a-form-item>
        <a-form-item name="apply_phone" label="电话">
          <a-input
            size="large"
            readonly
            v-model:value="activity_apply.apply_phone"
          >
          </a-input>
        </a-form-item>
        <a-form-item
          v-if="activity_apply.apply_idcard"
          name="apply_idcard"
          label="身份证号"
        >
          <a-input
            size="large"
            readonly
            v-model:value="activity_apply.apply_idcard"
          >
          </a-input>
        </a-form-item>
        <a-form-item name="apply_status" label="报名状态">
          <span>{{
            activity_apply.apply_status == 1 ? "已报名" : "已取消"
          }}</span>
        </a-form-item>
        <a-form-item
          class="apply-btn-wrap"
          v-if="activity_apply.apply_status == 1"
        >
          <a-button
            block
            size="large"
            type="primary"
            html-type="submit"
            class="btn"
            :loading="loadingBtn"
            @click="updateActivityApplyStatusDataFun"
          >
            取消
          </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import {
  getActivityData,
  updateActivityApplyStatusData,
  getActivityApplyData
} from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useRoute } from "vue-router";
import { encode, decode } from "js-base64";
import ActivityTop from "@/components/activityTop.vue";
export default defineComponent({
  name: "activityApplyDetail",
  components: {
    ActivityTop
  },
  setup() {
    const route = useRoute();
    const pageData = reactive({
      activity: null,
      applyId: null,
      activityId: null,
      user_id: computed(() => getUserId()),
      loading: true,
      loadingBtn: false,
      activity_apply: {}
    });
    const getActivityDataFun = _activityId => {
      getActivityData({ activity_id: _activityId })
        .then(res => {
          if (res.code == 200) {
            pageData.activity = res.data.activity;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const getActivityApplyDataFun = () => {
      getActivityApplyData({
        apply_id: pageData.applyId,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.activity_apply = res.data.activity_apply;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getActivityApplyDataFun();
    const updateActivityApplyStatusDataFun = e => {
      e.preventDefault();
      pageData.loading = true;
      pageData.loadingBtn = true;
      updateActivityApplyStatusData({
        apply_id: pageData.applyId,
        user_id: pageData.user_id,
        apply_status: -1
      })
        .then(res => {
          if (res.code == 200) {
            message.info("取消成功");
            getActivityApplyDataFun();
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
          pageData.loadingBtn = false;
        });
    };

    watch(
      () => route.query,
      val => {
        if (route.path == "/activity/applyDetail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.applyId = query[0];
            pageData.activityId = query[1];
            getActivityDataFun(query[1]);
            getActivityApplyDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.activity = null;
            pageData.activity_apply = null;
            message.error("暂无活动报名信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      updateActivityApplyStatusDataFun,
      labelCol: { style: { width: "100px" } }
    };
  }
});
</script>
<style lang="less">
.activity-apply {
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin-top: 30px;
  }
  .apply-form {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-row {
      width: 800px;
    }
  }
  .ant-form-item {
    align-items: center;
  }
  .apply-btn-wrap {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
